<script lang="ts" setup>
const isBackgroundLoaded = ref(false)
const isMobile = useIsMobileScreen()
</script>

<template>
  <div class="relative flex min-h-screen flex-1 flex-col justify-center px-4 sm:px-6 lg:px-8 bg-gray-50 bg-cover">
    <UnLazyImage
      v-if="!isMobile"
      src="/branding/background.jpg"
      alt="Background image"
      class="absolute top-0 left-0 h-full w-full object-cover transition-opacity duration-700"
      :class="isBackgroundLoaded ? 'opacity-100' : 'opacity-0'"
      :preload="false"
      :lazy-load="true"
      @loaded="isBackgroundLoaded = true"
    />

    <div class="absolute inset-0 h-full w-full bg-gray-50 md:bg-transparent bg-gradient-to-b from-gray-50 via-gray-50/80 to-gray-50" />

    <div class="relative sm:mx-auto sm:w-full sm:max-w-md -mt-20">
      <TheLogo class="text-3xl md:text-4xl" />
    </div>

    <slot />
  </div>
</template>
